
















































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class SendCustomNotificationPopup extends Vue {
  @Prop() popupId!: string
  @Prop() checkedUsersList!: Object
  @Prop() selectAllPicklrs!: boolean
  @Prop() picklrFilters!: Object
  public title: string = ''
  public message: string = ''

  public submittingForm = false
  public submitButtonText = 'SEND'

  sendNotificationToPicklrs () {
    this.submittingForm = true
    this.submitButtonText = 'SENDING'
    this.$validator.validateAll('send-notification').then((result) => {
      if (result) {
        let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
        let payload = { title: this.title, message: this.message, 'userIds': this.checkedUsersList, 'selected_all': this.selectAllPicklrs, 'filters': this.picklrFilters }
        this.$store.dispatch('sendCustomNotificationToPicklrs', payload).then((response) => {
          this.$notify({ text: response.body.message, type: 'success' })
          this.$emit('notificationSent', 'Notification sent successfully')
          Object.assign(this.$data, (this.$options.data as any).apply(this))
          this.$nextTick()
            .then(() => {
              this.$validator.errors.clear('send-notification')
            })
          popupCloseButton.click()
          this.submittingForm = false
          this.submitButtonText = 'SEND'
        }, response => this.$notify({ text: 'Can not send notification', type: 'error' }))
      } else if (this.$validator.errors.has('send-notification.message')) {
        this.$notify({ text: this.$validator.errors.first('send-notification.message'), type: 'error' })
        this.submittingForm = false
        this.submitButtonText = 'SEND'
      }
    })
  }
}
